// icons imports
import {
  mdiHome,
  mdiAccountMultiple,
  mdiDomain,
  mdiReceipt,
  mdiCash,
  mdiAccountMultipleOutline,
  mdiTicketPercent,
  mdiAutorenew,
  mdiShieldOutline,
  mdiAccountBoxMultiple,
} from "@mdi/js";
import type { AdminRole } from "@mono/constants/lib/adminAccessRules";
import type { RoutesNamedLocations } from "@typed-router";

// can be used to add links to the sidebar
// either as a single link or as a group of links
type Link = {
  title: string;
  to: RoutesNamedLocations;
  icon?: string;
  subLinks?: never;
  exact?: boolean;
  adminRolesAllowed?: AdminRole[];
};

const sidebarLinks: Link[] = [
  {
    title: "Home",
    to: { name: "dashboard" },
    icon: mdiHome,
    exact: true,
    adminRolesAllowed: ["support"],
  },
  {
    title: "CRM",
    to: { name: "dashboard-crm" },
    icon: mdiAccountBoxMultiple,
    adminRolesAllowed: ["sales", "sales-manager"],
  },
  {
    title: "Manage Users",
    to: { name: "dashboard-users" },
    icon: mdiAccountMultiple,
  },
  {
    title: "Manage Organizations",
    to: { name: "dashboard-organizations" },
    icon: mdiDomain,
  },
  {
    title: "Manage Orders",
    to: { name: "dashboard-orders" },
    icon: mdiReceipt,
    adminRolesAllowed: ["support"],
  },
  {
    title: "Manage Subscriptions Renewals",
    to: { name: "dashboard-subscription-renewals" },
    icon: mdiAutorenew,
    adminRolesAllowed: ["support"],
  },
  {
    title: "Financial Analytics",
    to: { name: "dashboard-financial" },
    icon: mdiCash,
  },
  {
    title: "Manage Affiliates",
    to: { name: "dashboard-affiliates" },
    icon: mdiAccountMultipleOutline,
  },
  {
    title: "Manage Promotions",
    to: { name: "dashboard-promotions" },
    icon: mdiTicketPercent,
  },
  {
    title: "Manage Plans",
    to: { name: "dashboard-plans" },
    icon: mdiCash,
  },
  {
    title: "Manage Admins",
    to: { name: "dashboard-admins" },
    icon: mdiAccountMultipleOutline,
  },
  {
    title: "Manage Zatca",
    to: { name: "dashboard-zatca" },
    icon: mdiShieldOutline,
    adminRolesAllowed: ["support"],
  },
];

export const useSidebarLinks = () => {
  return {
    sidebarLinks,
  };
};
